
#home {
    position: relative;
    background: url('../../assets/bgIMG.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper {
        padding: 0; 

        .copyright {
            display: none;
        }
    }
}



.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    margin-left: -3rem;

    justify-content: center;
    align-items: center;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px) { 
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px) {
        flex:1;
        flex-direction: column;
        align-items: center;
        margin-left: 0.5rem;
        margin-top: -7rem;
        //font-size: 10rem;
        text-align: center;
    } 

    @media screen and (max-width: 450px) { 
        padding-top: 6rem 1rem 2rem;
    } 
}

.app__header-info {

    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge {

    display: flex;
    align-items: flex-start;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    

   
    align-items: center;

   .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p{
        width: 100%;
        text-transform: uppercase;
        text-align: right;
    }
   }

   span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
        font-size: 5rem;
    }
    }

    @media screen and (max-width: 1200px) {
        
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.app__header-circles {
    flex: 1;
    margin-left: -3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    height: 100%;


    @media screen and (min-width: 2000px) { 
            margin: 1rem;
            width: 400px;
            height: 400px;
    }

    @media screen and (max-width: 1200px) { 
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;
    }
}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img {
        width: 100%;
        object-fit: contain;
        z-index: 1;
    }

    .overlay_circle {
        position: absolute;
        z-index: 0; 
        width: 100%;
        height: 100%;
        margin-right: 4rem;
        margin-bottom: 4rem;
    }

    @media screen and (max-width: 1200px) { 
        margin: 2rem 0;
    }
}