
.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    .app__work-filter-item {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: rgb(211, 211, 211);
        color: rgb(51, 51, 51);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
        margin: 0.6rem;

        &:hover {
            background-color: var(--secondary-color);
            color: #fff;
        }

        @media screen and (min-width: 2000px) {
          padding: 1 rem 2rem;
          border-radius: 0.85rem;  
        }
    }

    .item-active {
        background-color: var(--secondary-color);
            color: #fff;
    }
}

.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item {
        width: 270px;
        flex-direction: column;

        margin: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;

        color: #000;

        cursor: pointer;
        transition: all 0.3s ease;

        @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }

        @media screen and (max-width: 300px) {
            width: 100%;
            margin: 1rem;
        }
    }
}

.app__work-img {
    width: 100%;
    height: 230px;
    
    position: relative;
    img {
        width: 100%;
        height: 100%;
        border-radius:  0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        height: 350px;
    }
}

.app__work-hover {
    
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    //flex-wrap: wrap;
    
    width: 100%;
    height: 100%;
    background-color: rgba(56, 50, 50, 0.65);

    border-radius: 0.5rem;
    opacity: 0; //starting position
    transition: all 0.3s ease;
}

.app__work-content {
    padding: 0.7rem;
    width: 100%;
    position: relative;
    flex-direction: row;

    h4 {
        margin-top: 1rem;
        line-height: 1.5;
    }
    }


.app__icons {
        position: relative;
        width: 45px;
        height: 45px;
        border-radius: 50%; 
        background-color: rgba(104, 104, 104, 0.5);
        color: rgb(211, 211, 211);

        margin: 0.5rem;
        cursor: pointer;
        transition: all 0.3s ease;

        justify-content: center;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        svg {
            flex-direction: row;
            width: 50%;
            height: 50%;
            color: var(--white-color)
        
    }
    
}


